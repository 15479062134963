import './bootstrap';
import '../css/app.css';
import 'aos/dist/aos.css'

import {createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';
import {i18nVue} from 'laravel-vue-i18n';
import {faCcStripe, faGoogle} from '@fortawesome/free-brands-svg-icons';
import filters from '@/filters.js';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import money from 'v-money3'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faEdit,
    faExclamationCircle,
    faCheckCircle,
    faUserShield,
    faTrashCan,
    faCircle,
    faPeopleGroup,
    faChartColumn,
    faCheckSquare,
    faBan,
    faFilePdf,
    faSquarePlus,
    faSquareMinus,
    faMap,
    faCheck,
    faCaretDown,
    faCaretUp,
    faUserGroup,
    faHouse,
    faCommentsDollar,
    faGift,
    faCreditCard,
    faTableList,
    faUser,
    faCircleDot,
    faRightFromBracket,
    faKey, faUserSlash,
} from '@fortawesome/free-solid-svg-icons';

library.add(faEdit, faExclamationCircle, faCheckCircle, faUserShield, faTrashCan, faCircle, faPeopleGroup, faGoogle,
    faChartColumn, faCheckCircle, faCheckSquare, faBan, faFilePdf, faSquarePlus, faSquareMinus, faMap, faCheck, faCaretUp,
    faCaretDown, faCaretUp, faCcStripe, faCircleDot, faUserGroup, faHouse, faCommentsDollar, faGift, faCreditCard, faTableList,
    faUser, faRightFromBracket, faKey, faUserSlash);

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const application = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(i18nVue, {
                resolve: async lang => {
                    const langs = import.meta.glob('../../lang/*.json');
                    return await langs[`../../lang/${lang}.json`]();
                    /* if (lang.startsWith("php_")) {
                                return await langs[`../../lang/${lang}.json`]();
                            } else {
                                return await langs[`../../lang/php_${lang}.json`]();
                            } */
                }
            })
            .use(VueSweetalert2)
            .use(money, { precision: 2, thousands: '.', decimal: ',' })
            .component('FontAwesomeIcon', FontAwesomeIcon)
            .component('VueDatePicker', VueDatePicker);

        application.config.globalProperties.$filters = filters;

        // @see https://github.com/inertiajs/inertia/discussions/695
        delete el.dataset.page;

        application.mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
